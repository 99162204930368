.nav-primary__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $gradientcolorreverse;
    opacity: 0;
    visibility: hidden;
    transition: all 0.8s;
    z-index: 9999;
    overflow: auto;

    .menu__word__container {
        color: #fff;
    }

    .menu__icon span {
        background: #fff;
    }

    .product__menu {
        background-color: #fff;
        border-color: #fff;
    }

    .menu__icon__bars {
        margin-top: 10px;
    }

    .language__switcher {
        svg {
            color: #fff;
        }

        li a {
            color: #fff;
        }

        .sub-menu li a {
            color: $primarycolor;
        }
    }

    .menu__word {
        color: #fff;
    }
}

.wpml-ls-display {
    font-size: 17px;
    font-weight: 600;
}

.nav-primary__overlay--active {
    opacity: 1;
    visibility: visible;
}

.nav-primary {
    position: relative;
    z-index: 5;

    a {
        color: #fff;
    }

    ul,
    li {
        list-style: none;
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;

        .fa-angle-down {
            margin-left: 10px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .nav {
        > li {
        }

        .sub-menu {
            position: absolute;
            min-width: 220px;
            display: none;

            &.sub-menu1 {
                top: 100%;
                left: 0;

                > li {
                    a {
                        display: block;
                    }

                    .fa-angle-right {
                        float: right;
                        margin-top: 4px;
                    }
                }
            }

            &.sub-menu2 {
                top: 0;
                left: 100%;

                > li {
                    a {
                        display: block;
                    }

                    .fa-angle-right {
                        float: right;
                        margin-top: 4px;
                    }
                }
            }

            &.sub-menu3 {
                top: 0;
                left: 100%;

                > li {
                    a {
                        display: block;
                    }
                }
            }
        }
    }
}

// Primary Menu
.no-touchevents {
    .nav-primary {
        .nav {
            > li {
                &:hover,
                &:focus {
                    .sub-menu {
                        &.sub-menu1 {
                            display: block;
                        }
                    }
                }
            }

            .sub-menu {
                &.sub-menu1 {
                    > li {
                        &:hover,
                        &:focus {
                            .sub-menu {
                                &.sub-menu2 {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.sub-menu2 {
                    > li {
                        &:hover,
                        &:focus {
                            .sub-menu {
                                &.sub-menu3 {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.sub-menu3 {
                    > li {
                        &:hover,
                        &:focus {
                        }
                    }
                }
            }
        }
    }
}

.touchevents {
    .menu-item-has-children__overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .menu-item-has-children {
        &.menu-item-has-children__open {
            background-color: darken($primarycolor, 5) !important;

            > .menu-item-has-children__overlay {
                display: none;
            }

            > .sub-menu {
                display: block;
            }
        }
    }

    .sub-menu1 {
        .menu-item-has-children {
            &.menu-item-has-children__open {
                background-color: darken($primarycolor, 10) !important;
            }
        }
    }

    .sub-menu2 {
        .menu-item-has-children {
            &.menu-item-has-children__open {
                background-color: darken($primarycolor, 15) !important;
            }
        }
    }
}

.menu__word {
    display: inline-block;
    color: white;
    font-size: 18px;
    font-weight: 600;
}

.menu__word__close {
    display: none;
}

.menu__icon__bars {
    position: relative;
    display: inline-block;
    width: 16px;
    margin-top: 12px;
}

.menu__word__container {
    display: inline-block;
    margin-left: 13px;
}

// Menu bar icon
.menu__icon {
    width: auto;
    min-width: 121px;
    z-index: 10;
    background: -webkit-gradient(linear, left top, right top, from(#049de0), to(#00438d));
    background: -webkit-linear-gradient(left, #049de0 0%, #00438d 100%);
    background: -o-linear-gradient(left, #049de0 0%, #00438d 100%);
    background: linear-gradient(90deg, #049de0 0%, #00438d 100%);
    border-radius: 999px;
    text-align: right;
    transform: rotate(0deg);
    cursor: pointer;
    padding: 0 32px;
    display: flex;
    align-items: center;
    height: 56px;

    span {
        display: block;
        position: absolute;
        height: 1.7px;
        width: 16px;
        background: white;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        top: 0;
        transform: rotate(0deg);
        transition: 150ms ease-in-out;

        &:nth-child(1) {
            top: -12px;
        }

        &:nth-child(2) {
            top: -7px;
        }

        &:nth-child(3) {
            top: -2px;
        }
    }

    &.open {
        background: white;

        .menu__word {
            color: $primarycolor;
        }

        span {
            background: $primarycolor;

            &:nth-child(1) {
                top: -6px;
                width: 0%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
                top: -6px;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                top: -6px;
            }
        }
    }
}

.nav__primary__top {
    background: linear-gradient(90deg, #003f84 0%, #0377b7 100%);
}

.nav-primary__inner {
    margin-top: 50px;
}

.menu__overlay__title {
    margin-bottom: 20px;

    a {
        font-size: 26px;
        color: #fff;
        font-weight: 500;
    }

    span {
        font-size: 26px;
        color: #fff;
        font-weight: 500;
    }
}

.header .nav a {
    padding: 5px 0;
}

.search-form {
    height: 100%;
}

.menu__overlay__search {
    position: relative;
    width: auto;
    z-index: 10;
    margin: 0 0 0 auto;
    border-radius: 5px;
    height: 56px;
    border: 1px solid #6496ba;
    color: #1a518e;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;

    label {
        margin-bottom: 0;
    }

    svg {
        color: #fff;
    }

    .search-field {
        background-color: transparent;
        padding: 0 25px;
        margin: 0;
        border: 0;
        min-width: 310px;
        height: auto;
        color: #fff;
        text-transform: initial;
        letter-spacing: 0;
        font-size: 17px;
        font-weight: 400;

        &::placeholder {
            color: #fff;
        }

        &:focus {
            background-color: transparent;
            color: #fff;
            outline: none;
            box-shadow: none;
        }
    }

    button {
        color: #fff;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 15px;
            color: #fff;
        }
    }

    .search-submit {
        background-color: transparent;
        padding: 0;
        color: #fff;

        svg {
            width: 15px;
            height: 15px;
            margin-right: 24px;
            color: #fff;
            margin-top: -2px;
        }

        &:hover {
            background-color: transparent;
        }

        &:focus {
            box-shadow: none !important;
            background-color: transparent !important;
        }
    }
}

.nav-primary li a {
    position: relative;
    padding-left: 20px !important;

    &::before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        background-image: url('../../images/angle-right-regular-white.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 8px;
        height: 15px;
        transition: all 0.3s ease;
    }

    &:hover {
        &::before {
            left: 3px;
        }
    }
}

.menu__open {
    overflow-y: hidden;
}

.menu__overlay__socials {
    margin-top: 30px;
    margin-bottom: 50px;

    .footer__social__link {
        svg {
            fill: #fff;
        }
    }
}
