.top__blue__layer {
    position: relative;
    z-index: 2;
    background-image: url('../../images/bubbles.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 495px;
    margin-bottom: calc((56.25% / 1.725) / 3);

    &__title {
        position: absolute;
        top: 0;
        z-index: 50;
        text-align: center;
        height: 100%;
        padding-bottom: calc((56.25% / 1.725) / 3);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1280px) {
            margin-bottom: calc((56.25% / 1.725) / 2);
        }

        @media screen and (max-width: 640px) {
            margin-bottom: calc((56.25% / 1.725) / 1);
        }

        > * {
            color: white;
            margin: 0;
            font-size: 52px;
            font-weight: 600;
            line-height: 1;

            @media screen and (max-width: 1280px) {
                font-size: 40px;
            }

            @media screen and (max-width: 640px) {
                font-size: 30px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .top__blue__layer {
        margin-bottom: calc((56.25% / 1.725) / 2);
    }
}

@media screen and (max-width: 640px) {
    .top__blue__layer {
        margin-bottom: calc((56.25% / 1.725) / 1);
    }
}

.banner__vid {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 613px;
}

.video__container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.front__solutions__image {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    transition: transform ease-in-out 0.25s;
}

.front__solutions__text__slider,
.front__solutions__canvas__slider {
    transition: all 0.3s ease;
}

.front__solutions__canvas__slider .slick-list {
    overflow: visible;
    padding: 0 !important;
    margin: 0 -15px;
}

.front__solutions__text__inner {
    h3 {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 500;
        line-height: 36px;
        color: #fff;
    }

    p {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 28px;
        color: #fff;
    }

    .btn-hollow {
        &:hover {
            color: $primarycolor;
            background-color: #fff;
        }
    }

    .front__solutions__text__inner__content {
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        transition: 0.25s ease-in-out all;

        > span {
            min-height: 0;
            visibility: hidden;
            transition: 0.25s ease-in-out visibility;
        }
    }
}

.solution__item:hover {
    .front__solutions__title {
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.52) 0, rgba(0, 0, 0, 0) 100%);
        transition: all ease-in-out 0.25s;
    }

    .front__solutions__image {
        transform: scale(1.05);
    }

    .front__solutions__text__inner .front__solutions__text__inner__content {
        grid-template-rows: 1fr;

        > span {
            visibility: visible !important;
        }
    }
}

.front__solutions__text {
    display: block;
    margin: 160px 0 230px;

    .slick-dots {
        position: absolute;
        bottom: -155px;
        left: 14px;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;

        li {
            position: relative;
            display: inline-block;
            width: 29px;
            height: 6px;
            margin: 0 3px;
            padding: 0;
            cursor: pointer;
            transition: background-color 0.5s;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.59);
        }

        .slick-active {
            background-color: #fff;
        }

        li button::before {
            display: none;
        }
    }
}

.front__solutions__slider {
    position: relative;
    top: 100%;
    transform: translateY(-50%);
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    z-index: 50;

    .slick-list {
        overflow-y: visible;
    }

    .slick-dotted {
        &.slick-slider {
            margin-bottom: 0;
        }
    }
}

@media all and (min-width: 2500px) {
    .video__container {
        video {
            width: 100%;
        }
    }
}

.solution__item {
    position: relative;
    padding: 0 15px;
    outline: none;
}

.solution__item__holder {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.front__solutions__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: max-content;
    padding: 0 35px 47px 56px;
    transition: all ease-in-out 1s;
    transform: rotateY(0deg);
    font-size: 27px;
    font-weight: 600;
    line-height: 35px;
    color: #fff;
    display: flex;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.52) 0, rgba(0, 0, 0, 0) 100%);

    @media screen and (max-width: 1536px) {
        padding: 0 30px 26px 28px;
    }
}

.banner {
    position: relative;
    margin-bottom: 50px;
    background-color: $gradientcolorreverse;
    background-image: url('../../images/bubbles.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        content: '';
        background-color: #fff;
    }
}

.banner__inner {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: 10%;
    border-radius: 10px;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.19);

    img {
        width: 100%;
        height: auto !important;
    }
}

.gm-style-iw-d {
    padding: 14px 20px;
}

.marker__text {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: center;

    &.marker__title {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
        color: $primarycolor;
    }

    &.telefoon {
        margin-bottom: 13px;
    }
}

#wpsl-result-list {
    display: none !important;
}

#wpsl-gmap {
    width: 100% !important;
}
