/* Pagination numbered */
.pagination__numbers {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    border-top: 1px solid #eee;

    .page-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        width: 30px;
        height: 30px;
        margin: 0 3px;
        font-size: 15px;
        text-decoration: none;
        color: #fff;
        border-radius: 4px;
        background: $primarycolor;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: darken($primarycolor, 5);
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

.row--gallery {
    margin-top: 35px;
    margin-bottom: 35px;

    img {
        border-radius: 5px;
    }
}

.intro__block {
    position: relative;
    overflow-x: hidden;
    padding: 96px 0;

    h1 {
        font-size: 30px;
        line-height: 36px;
        font-weight: 400;
        margin-bottom: 9px;
    }

    h2 {
        font-size: 48px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 29px;
    }

    p {
        margin-bottom: 39px;
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
    }

    .btn {
        margin: 9px;
    }
}

.intro__block__right {
    z-index: 1;
    padding: 70px 0 30px 150px;
    text-align: center;

    p {
        font-size: 25px;
        line-height: 36px;
        color: $primarycolor;
    }
}

.intro__block__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    right: -5vw;
    width: 45vw;
    height: 100%;
    background: linear-gradient(-180deg, #fff 0%, #e4ebee 100%);

    &::before {
        position: absolute;
        top: 0;
        right: calc(100% - 25px);
        width: 50px;
        height: 100%;
        max-height: 800px;
        content: '';
        transform: skew(-4deg);
        background-color: #fff;
    }
}

.intro__block__down {
    display: block;
    margin-top: 30px;
}

.intro__block__down svg {
    width: 30px;
    height: 30px;
    color: $primarycolor;
}

.sidebar__container {
    margin-top: 35px;
    padding: 40px 47px;
    border-radius: 19px;
    background: #e1e9ec; /* Old browsers */
    background: -moz-linear-gradient(top, #f9fafa 0%, #e1e9ec 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f9fafa 0%, #e1e9ec 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f9fafa 0%, #e1e9ec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.sidebar__container__title {
    position: relative;
    display: block;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 400;
    color: $primarycolor;
}

.sidebar__container__items {
    position: relative;
    display: block;

    ul {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            display: block;
            margin: 0;
            padding: 0;

            a,
            .sidebar__tel {
                position: relative;
                display: block;
                overflow: hidden;
                margin-top: 10px;
                padding: 10px 45px 10px 28px;
                transition: all 0.3s;
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: $primarycolor;
                border: 1px solid $primarycolor;
                border-radius: 40px;

                svg {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 8px;
                    height: 21px;
                    margin-top: -11px;
                }

                &:hover {
                    color: #fff;
                    background-color: $primarycolor;

                    svg {
                        color: #fff;
                    }
                }
            }

            .sidebar__tel {
                &:hover {
                    color: $primarycolor;
                    background-color: transparent;
                }
            }
        }
    }
}

.branches__cta__layer {
    padding: 120px 0;
    text-align: center;
    background: $gradientcolor;
}

.branches__cta__layer__content {
    h3 {
        margin-top: 0;
        margin-bottom: 40px;
        font-size: 31px;
        line-height: 40px;
        color: #fff;
    }

    b {
        display: block;
    }

    .btn {
        margin: 0 10px;
        font-size: 17px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.content__bg {
    padding: 25px 30px;
    border-radius: 10px;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.content__bg__border {
    border: 1px solid $secondarycolor;
}

.content__bg__darkblue {
    background-color: $primarycolor;
    color: #fff;
}

.content__bg__lightblue {
    background-color: $secondarycolor;
}

.content__bg__green {
    background-color: #449945;
    color: #fff;
}

.content__sub__single__nomargin {
    padding-bottom: 0;
}

.brochure__title {
    padding: 25px 0 5px;
}

.brochures__overview {
    padding-top: 60px;

    > div {
        &:first-child {
            padding-top: 0;
        }
    }

    .btn-white {
        margin-bottom: 20px;
        background-color: #f4f4f4;
    }
}

.news__intro {
    margin-bottom: 40px;
}
