body {
    font-family: $font-secondary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;
}

.body--open {
    margin-top: 166px;
}

.main {
    padding: 0;
}

.block {
    padding: 40px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    color: $header-color;
}

h1 {
    margin-bottom: 30px;
    font-size: 31px;
    font-weight: $header-font-weight;
}

h2 {
    margin-bottom: 20px;
    font-size: 28px;
}

h3 {
    margin-bottom: 15px;
    font-size: 26px;
}

h4 {
    margin-bottom: 15px;
    font-size: 22px;
}

h5 {
    margin-bottom: 15px;
    font-size: 20px;
}

h6 {
    margin-bottom: 15px;
    font-size: 18px;
}

a {
    &:hover {
        text-decoration: none;
    }
}

b {
    font-weight: 600;
}

.gform_wrapper {
    @extend .contact__form;
}

.acf-map {
    width: 100%;
    height: 425px;
    margin: 0;
    border: 1px solid #e4e4e4;
}

.acf-map img {
    max-width: inherit !important;
}

.global__col {
    margin-bottom: 40px;

    a {
        display: block;
        overflow: hidden;
        border: 1px solid #d9e4e8;
        border-radius: 10px;

        &:hover {
            text-decoration: none;
        }
    }

    &:active,
    &:focus {
        .btn {
            box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
        }
    }

    &:hover {
        .btn {
            box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
        }

        .global__image__inner {
            img {
                transform: scale(1.05);
            }
        }
    }
}

.global__content {
    display: block;
    padding: 36px 40px 38px 51px;

    &:hover {
        text-decoration: none;
    }
}

.global__image {
    position: relative;
    display: block;
}

.global__image__inner {
    display: block;
    overflow: hidden;
    border-radius: 10px 10px 0 0;

    img {
        transition: all 0.5s ease;
        border-radius: 10px 10px 0 0;
    }
}

.global__title {
    display: block;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 600;
    line-height: 21px;
    color: $primarycolor;
}

.global__date {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: $body-font-color;
}

.global__text {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    color: $body-font-color;
}

.overview__padding {
    padding: 0 0 80px 0;
}

.content__sub__single {
    position: relative;
    padding: 0 0 80px 0;

    .btn {
        margin-top: 10px;
    }

    iframe {
        max-width: 100%;
    }
}

.single__sidebar {
    max-width: 380px;
    margin-top: 25px;

    img {
        border-radius: 10px;
    }
}

.single__date {
    display: block;
    margin-top: -20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
}

.single__sidebar__title {
    margin-bottom: 30px;
    font-size: 25px;
    line-height: 30px;
    color: $primarycolor;
}

.relation__arrow__left {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -45px;
    display: block;
    width: 24px;
    height: 42px;
    margin-top: -14px;
    padding: 0;
    cursor: pointer;
    transform: translate(0, -50%);
    font-size: 0;
    line-height: 0;
    color: transparent;
    border: none;
    outline: none;
    background-image: url('../../images/angle-left.svg');
    background-repeat: no-repeat;
    background-size: 24px 42px;
}

.relation__arrow__right {
    position: absolute;
    top: 50%;
    right: -45px;
    display: block;
    width: 24px;
    height: 42px;
    margin-top: -14px;
    padding: 0;
    cursor: pointer;
    transform: translate(0, -50%);
    font-size: 0;
    line-height: 0;
    color: transparent;
    border: none;
    outline: none;
    background-image: url('../../images/angle-right.svg');
    background-repeat: no-repeat;
    background-size: 24px 42px;
}
