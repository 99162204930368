/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$grid-gutter-width: 40px !default;

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #1a518e;
$secondarycolor: #d9e6ec;
$thirdcolor: #d9e4e8;
$fourthcolor: #ecf1f3;
$gradientcolor: linear-gradient(90deg, rgba(4, 157, 224, 1) 0%, rgba(0, 67, 141, 1) 100%);
$gradientcolorreverse: linear-gradient(90deg, rgba(0, 67, 141, 1) 0%, rgba(4, 157, 224, 1) 100%);

$font-primary: 'Poppins';
$font-secondary: 'Poppins';
$body-font-size: 18px;
$body-font-weight: 300;
$body-line-height: 1.6;
$body-font-color: #5c5c5c;

$header-font-family: 'Poppins';
$header-color: $primarycolor;
$header-font-weight: 600;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;
