.verkooppunten__filter {
    .btn {
        border: 2px solid #a1b7d0;
        color: #5c738d;
        margin: 16px 10px 0 0;
        padding: 10px 19px;
        font-size: 17px;
        background-color: transparent;

        &.europe {
            background-color: $primarycolor;
            border-color: $primarycolor;
            color: #fff;
        }

        &:hover,
        &:active,
        &:focus {
            border-color: $primarycolor;
            box-shadow: none;
        }

        &.active {
            background-color: $primarycolor;
            border-color: $primarycolor;
            color: #fff;
        }
    }
}

.verkooppunten__map {
    position: relative;

    .acf-map {
        height: 860px;
    }
}

.verkooppunten__map__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    .container-fluid {
        height: 100%;

        > .row {
            height: 100%;
        }
    }
}

.verkooppunten__filter__mobile {
    display: none;
}

.verkooppunten__search {
    position: absolute;
    top: calc(50% - 35px);
    left: 20px;
    width: 350px;
    padding: 19px 60px 19px 30px;
    background-color: $primarycolor;
    border-radius: 35px;
    text-transform: uppercase;
    pointer-events: all;

    input {
        width: 100%;
        background: transparent;
        border: none;
        color: #fff;
        outline: none;

        &::placeholder {
            color: #fff;
            text-transform: uppercase;
        }
    }
}

.verkooppunt__search__icon {
    position: absolute;
    top: calc(50% - 16px);
    right: 22px;
    cursor: pointer;

    svg {
        height: 23px;
        width: 22px;
        fill: #fff;
        color: #fff;
    }
}

.gm-style-iw,
.store_locator_infowindow {
    white-space: inherit;
}

.gm-style-iw-d {
    padding: 14px 20px 18px 10px;
}

.store_locator_name {
    font-size: 18px;

    i {
        display: none;
    }
}

.store_locator_email {
    margin-top: 18px;
    display: block;
}

.store_locator_details {
    font-size: 15px;
    line-height: 1.4;
}

.store_locator_dragged_button {
    display: none !important;
}

.control input:checked ~ .control__indicator {
    background: #60646c;
}

.store_locator_category_filter_image {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    color: #5c738d;
    margin: 16px 10px 0 0;
    padding: 10px 19px;
    font-size: 17px;
    background-color: transparent;
    border: 2px solid #a1b7d0;
    line-height: 1.5;
    border-radius: 50px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;

    img,
    br {
        display: none;
    }

    &:hover {
        border-color: $primarycolor;
    }
}

.store_locator_category_filter_image_name {
    font-size: 17px;
}

.store_locator_filter_content {
    div {
        display: none;

        &:first-child {
            display: block;
        }
    }
}

.store_locator_category_filter_image_selected {
    background-color: $primarycolor;
    border-color: $primarycolor;
    color: #fff !important;
}

.gm-style-cc {
    input {
        position: absolute;
        top: calc(50% - 35px);
        left: 20px;
        font-size: 18px;
        height: auto;
        width: 350px !important;
        padding: 19px 60px 19px 30px;
        background-color: #1a518e;
        border-radius: 35px;
        text-transform: uppercase;
        pointer-events: all;
        border: none;
        color: #fff !important;
        outline: none;
    }
}

.verkooppunten__filter__outer {
    position: relative;
}

.verkooppunten__filter__title {
    display: none;
    border: 2px solid #a1b7d0;
    color: #5c738d;
    margin: 15px 0 0 0;
    padding: 10px 19px;
    background-color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    z-index: 1;
    position: relative;
}

.dealers__map {
    .fc-infowindow-udine .fc-item-box img {
        max-width: 190px;
        width: auto;
        margin: 0 auto;
    }

    .fc-infowindow-udine .fc-itemcontent-padding {
        padding: 27px 10px;
    }

    .wpgmp-map-2 .wpgmp_infowindow .fc-item-title.fc-item-primary-text-color {
        margin-bottom: -10px;
    }

    .wpgmp-map-2 .wpgmp_infowindow .fc-item-content.fc-item-body-text-color.fc-item-top-space {
        font-size: 15px;

        strong {
            font-weight: 700;
        }

        &.contact {
            font-size: 15px;

            strong {
                width: 13px;
                display: inline-block;
            }
        }
    }

    .btn {
        background: $primarycolor;
        color: #fff;
        padding: 6px 20px;
        font-size: 14px;
    }

    .soort__dealer {
        position: relative;
        width: 15px;
        height: 15px;
        border: 1px solid #eee;
    }

    .soort__dealer__on {
        position: absolute;
        left: 0;
        top: 0;
    }

    .fc-feature-img {
        margin-top: 0;
        margin-bottom: 16px;
    }

    .wpgmp_map_container {
        height: 100%;
    }

    .wpgmp_filter_wrappers {
        position: absolute;
        top: calc(50% - 68px);
        left: 10%;
        float: none;
        background: transparent;
        padding: 0;
        margin: 0;
        width: auto;
    }

    .wpgmp_map_parent {
        float: none;
        margin: 0;
    }

    .location_pagination2 {
        display: none;
    }

    div {
        &.wpgmp_listing_header {
            div {
                &.wpgmp_search_form {
                    position: relative;
                    width: auto;
                    z-index: 1;
                    margin: 0;

                    &::before {
                        position: absolute;
                        top: calc(50% - 11px);
                        right: 22px;
                        width: 22px;
                        height: 22px;
                        content: '';
                        background-image: url('../../svg/search-solid.svg');
                        background-repeat: no-repeat;
                        background-size: 22px 22px;
                    }

                    &::after {
                        display: none;
                    }

                    input {
                        &.wpgmp_search_input {
                            font-size: 18px;
                            height: auto;
                            width: 350px;
                            padding: 19px 60px 19px 30px;
                            background-color: #1a518e;
                            border-radius: 35px;
                            text-transform: uppercase;
                            pointer-events: all;
                            border-color: #1a518e;
                            color: #fff;
                            outline: none;

                            &:focus {
                                border-color: #1a518e;
                            }

                            &::placeholder {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.location_listing2 {
    display: none;
}

.wpgmp_before_listing {
    display: none;
}

.infoBoxTail::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
}
