@media screen and (max-width: 1700px) {
    .front__solutions__text {
        margin: 100px 0 110px;
    }

    .front__solutions__text .slick-dots {
        bottom: -5vw;
    }
}

@media screen and (max-width: 1400px) {
    .projects__relation {
        .relation__arrow__right {
            right: 45px;
        }

        .relation__arrow__left {
            left: 45px;
        }
    }

    .global__title {
        font-size: 20px;
    }
}
