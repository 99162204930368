.products__home {
    margin-top: 80px;
    padding: 45px 0;
    background: $gradientcolor;
}

.products__home__col {
    display: flex;
    text-align: center;
}

.product__home__link {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    flex-grow: 1;

    svg {
        width: 13px;
        height: 17px;
        color: #fff;
        margin-right: 8px;
    }

    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

.products__relation {
    text-align: center;
    padding-bottom: 80px;
}

.products__relation__title {
    margin: 80px 0 30px 0;

    h2 {
        font-size: 25px;

        b {
            display: block;
            text-transform: lowercase;
        }
    }

    h3 {
        font-size: 25px;

        b {
            display: block;
            text-transform: lowercase;
        }
    }
}

.products__relation__blocks {
    text-align: left;
}

.productgroup__team__contact__layer {
    display: block;
    padding: 80px 0;
    background-color: $fourthcolor;

    .team__photo {
        position: relative;
        top: auto;
        right: auto;
        border-radius: 10px;
        overflow: hidden;
    }
}

.productgroup__team__content {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.productgroup__team__title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
    color: $primarycolor;

    b {
        display: block;
    }
}

.product__specifications__layer {
    display: block;
    padding: 80px 0;
    margin-top: 80px;
    background-color: $fourthcolor;
}

.product__specifications__title {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 20px;
    color: $primarycolor;
}

.product__specifications__left {
    ul {
        list-style: none;
        padding-left: 17px;

        li {
            margin: 0 0 15px 0;
            padding: 0;
        }

        li::before {
            content: "\2022";
            color: $primarycolor;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
}

.product__team__layer {
    padding: 80px 0 0;
    display: block;
}

.product__team__link {
    display: block;
    padding-right: 60px;
}

.product__team__voordelen {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: flex-start;
        margin-bottom: 4px;

        svg {
            width: 20px;
            min-width: 20px;
            height: 24px;
            color: $primarycolor;
            margin-top: 1px;
            margin-right: 17px;
        }
    }
}

.btn-primary-team {
    position: absolute;
    left: 44%;
    bottom: 30px;
    transform: translate(-50%, -50%);
}

.product__team__photo {
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.products__relation--oplossingen {
    padding-bottom: 0;

    b {
        text-transform: lowercase;
    }
}

.download__btn {
    position: relative;
    width: 100%;
    text-align: left;
    height: 51px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 60px;
    padding-left: 25px;

    svg {
        position: absolute;
        right: 25px;
        top: 14px;
        width: 18px;
        height: 18px;
        color: $primarycolor;
        float: right;
        margin-top: 2px;
    }

    .download__btn__file {
        margin-right: 5px;

        svg {
            position: relative;
            right: auto;
            top: auto;
            float: none;
            width: 18px;
            height: 18px;
            margin: -2px 0 0 0;
        }
    }
}

.product__singular__blue__bar {
    padding: 27px 0;
    background: $gradientcolor;
    display: block;
    margin-bottom: 30px;
}

.products__relation__title.products__relation__title--productgroep b {
    text-transform: inherit;
}

.oplossingen__h2 {
    font-size: 22px;
}
