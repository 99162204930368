@import "~bootstrap/scss/bootstrap";

@media (min-width: 1200px) {
    .container {
        max-width: 1260px;
    }

    .container-fluid {
        max-width: 1539px;
    }
}
