.header .nav li {
    @extend .nav-item;
}

.header .nav a {
    @extend .nav-link;
}

.brand {
    display: inline-block;
    margin: 22px 0 0;

    img {
        width: 200px;
        height: 93px;
    }
}

.header {
    z-index: 10;
    width: 100%;
    transition: background-color 0.5s;
}

.header--fixed {
    position: fixed;
    top: 0;
    transition: background-color 0.5s;
    background-color: rgba(255, 255, 255, 0.93);
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.2);

    .header__inner {
        height: 115px;
    }

    .brand {
        width: 130px;
        height: 70px;

        img {
            width: 130px;
            height: 70px;
        }
    }
}

.right__menu__btns {
    display: flex;
    margin: 0 0 0 auto;
}

.language__switcher {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    float: right;
    width: auto;
    height: 56px;
    margin: 0 24px 0 0;
    padding: 0;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #1a518e;
    border-radius: 5px;

    &:hover {
        .language__switcher__dropdown {
            visibility: visible;
            opacity: 1;
        }
    }

    .language__switcher__dropdown {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        padding: 0 0;
        transition: all 0.5s;
        visibility: hidden;
        opacity: 0;
        border: 1px solid #6496ba;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.19);

        a {
            display: block;
            padding: 5px 0;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            border-bottom: 1px solid #9dbcd3;

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.nav-uitklap {
    display: inline-block;
}

.language__switcher__angle {
    display: inline-block;

    svg {
        width: 24px;
        height: 24px;
        margin-left: 3px;
    }
}

.live-search-container {
    position: relative;
    display: none;
    margin-top: 80px;
}

.overlay__inner__search__container {
    padding: 35px;
    border-radius: 0 0 10px 10px;
    background-color: #fff;
}

.overlay__search__form {
    width: 100%;
}

.header__inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 124px;
}

.menu__open {
    .product__menu {
        position: inherit;
        top: auto;
        right: auto;
        margin-right: auto;
    }

    .menu__overlay__search {
        margin: 0 10px 0 0;
    }

    .right__menu__btns {
        margin: 0 0 0 10px;
    }
}

.product__menu {
    z-index: 1000;
    margin-left: 75px;
    display: flex;
    align-items: center;
    width: auto;
    height: 56px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    color: $primarycolor;
    border: 1px solid #6496ba;
    border-radius: 999px;

    &:hover {
        border-color: #fff;
        border-radius: 5px 5px 0 0;
        background-color: #fff;

        .product__menu__inner {
            ul {
                display: inline-block;
                border-radius: 10px 0 10px 10px;
            }
        }
    }
}

.overlay__product__menu {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: opacity 0.3s;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);

    &.overlay__product__menu__show {
        z-index: 999;
        height: 100%;
        opacity: 1;
    }
}

.product__menu__inner {
    position: relative;
    padding: 0 20px 0 26px;

    > ul {
        position: absolute;
        top: 41px;
        right: -1px;
        display: none;
        width: 380px;
        margin: 0;
        padding: 30px 0 35px 0;
        list-style: none;
        border-radius: 10px;
        background-color: #fff;

        &:hover {
            > li {
                > .productcategory__item {
                    border-right: 1px solid #efefef;
                    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 90%, rgba(247, 250, 251, 1) 100%);
                    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 90%, rgba(247, 250, 251, 1) 100%);
                    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 90%, rgba(247, 250, 251, 1) 100%);

                    .productcategory__item__arrow {
                        svg {
                            opacity: 0;
                        }
                    }
                }
            }
        }

        > li {
            padding: 0 40px 0 40px;

            &:hover {
                > .productcategory__item {
                    .productcategory__item__title {
                        text-decoration: underline;
                    }
                }

                > .product__subcategorys {
                    display: block;

                    .back__button {
                        display: none;
                    }
                }
            }

            > .product__subcategorys {
                position: absolute;
                top: 0;
                left: 340px;
                display: none;
                align-items: flex-start;
                flex-wrap: wrap;
                width: 630px;
                min-height: 100%;
                line-height: 1.3;
                border-radius: 0 10px 10px 10px;
                background-color: #fff;
                justify-items: flex-start;

                .product__subcategorys__inner {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    justify-items: flex-start;
                    padding: 40px 40px 40px 40px;
                }

                a {
                    position: relative;
                    display: inline-block;
                    width: 166px;
                    max-height: 174px;
                    margin-top: 0;
                    margin-right: 25px;
                    margin-bottom: 25px;
                    padding: 15px;
                    transition: border-color 0.3s;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: none;
                    border: 1px solid #e5e5e5;
                    border-radius: 8px;

                    img {
                        border-radius: 5px;
                    }

                    span {
                        display: block;
                        max-width: 100%;
                        margin-top: 25px;
                        word-wrap: break-word;
                    }

                    &:nth-child(3n+3) {
                        margin-right: 0;
                    }

                    &:hover {
                        border-color: #c4c4c4;
                    }
                }
            }

            > .productcategory__item {
                position: relative;
                display: block;
                padding: 17px 25px 17px 43px;
                transition: all 0.3s;
                font-size: 16px;
                font-weight: 400;
                text-transform: none;
                color: $primarycolor;
                border-right: 1px solid transparent;
                border-bottom: 1px solid #efefef;

                &:hover {
                    border-right: 1px solid transparent;
                    background: transparent;

                    .productcategory__item__arrow {
                        svg {
                            opacity: 1;
                        }
                    }
                }

                .productcategory__item__icon {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: inline-block;
                    width: 38px;
                    margin-top: -14px;

                    img {
                        width: 28px;
                        height: 28px;
                    }

                    svg {
                        width: 28px;
                        height: 28px;
                        color: $primarycolor;
                        fill: $primarycolor;
                    }

                    &.icon__waterbeluchting {
                        margin-top: -14px;

                        img {
                            width: 28px;
                            height: 28px;
                        }

                        svg {
                            width: 28px;
                            height: 28px;
                        }
                    }

                    &.icon__fonteinen {
                        margin-top: -15px;

                        img {
                            width: 30px;
                            height: 30px;
                        }

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                .productcategory__item__arrow {
                    svg {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 15px;
                        height: 23px;
                        margin-top: -11.5px;
                        transition: all 0.3s;
                        color: #b9c7d9;
                    }
                }
            }

            &:last-child {
                .productcategory__item {
                    border-bottom: 0;
                }
            }
        }
    }
}

.touchevents {
    .productcategory__item {
        pointer-events: none;
    }

    .productcategory__item__overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .product__subcategorys {
        .back__button {
            position: relative;
            display: none;
            margin-top: 23px;
            font-size: 13px;
            text-transform: initial;

            img {
                width: 6px;
                height: 10px;
                margin-right: 8px;
            }
        }
    }

    .product__menu__inner {
        > ul {
            > li {
                position: relative;

                &.product__subcategorys--active {
                    position: initial;

                    .productcategory__item__overlay {
                        display: none;
                    }
                }
            }
        }
    }
}

.menu__overlay__search.menu__overlay__search--big {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;

    .search-form {
        width: 100%;
    }

    .search-field {
        width: calc(100% - 70px);
    }

    label {
        justify-content: flex-start;
        width: 100%;
    }

    .search-submit {
        margin-right: 10px;
        margin-left: auto;
    }
}

.overlay__inner__search__column {
    span {
        display: block;
        margin-bottom: 10px;
        font-size: 23px;
        font-weight: 600;
        line-height: 30px;
        color: $primarycolor;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        line-height: 30px;
        color: $primarycolor;

        &::before {
            display: inline-block;
            float: left;
            width: 8px;
            height: 15px;
            margin-top: 6px;
            margin-right: 10px;
            content: '';
            background-image: url('../../images/angle-right-regular.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}

.close__overlay__search {
    position: absolute;
    top: -50px;
    right: 30px;
    width: 60px;
    height: 50px;
    cursor: pointer;
    line-height: 46px;
    text-align: center;
    border-radius: 5px 5px 0 0;
    background-color: #fff;

    svg {
        width: 20px;
        height: 20px;
        color: $primarycolor;
    }
}
