@media screen and (max-width: 1199px) {
    .team__photo {
        position: relative;
        top: 0;
        margin-top: 20px;
    }

    .productgroup__team__contact__layer {
        .team__photo__image img {
            width: 210px;
            height: auto;
        }

        .team__home .team__photo__image img {
            width: 210px;
            height: auto;
        }
    }

    .global__content {
        padding: 35px 40px;
    }

    .front__solutions__title {
        font-size: 20px;
        line-height: 23px;
    }

    .front__solutions__text__inner h3 {
        font-size: 26px;
        line-height: 25px;
    }

    .intro__block {
        padding: 48px 0;
    }

    .intro__block__right {
        padding: 70px 50px;
    }

    .team__home {
        margin-bottom: 100px;

        .team__photo {
            margin-top: 0;
        }
    }

    .team__question h4 {
        font-size: 20px;
        line-height: 23px;
    }

    .team__contact__bg {
        padding: 64px 51px 59px 66px;
    }

    .product__home__link {
        font-size: 15px;
    }

    .footer {
        padding: 80px 0;
    }

    .brand {
        margin-top: 25px;
        margin-bottom: 0;
    }

    .front__solutions__text {
        margin: 50px 0 110px;
    }

    .front__solutions__text__inner p {
        margin-bottom: 30px;
    }

    .front__solutions__text .slick-dots {
        bottom: -55px;
    }

    .overview__branches,
    .overview__solutions {
        margin-top: 30px;
    }

    .intro__block--branches {
        padding-top: 0;
        padding-bottom: 80px;

        .intro__block__right {
            padding-left: 90px;
        }
    }

    .menu__overlay__search {
        .search-field {
            min-width: 190px;
            width: 190px;
        }

        .search-form {
            max-width: 225px;
        }
    }

    .overlay__inner__search__column {
        margin-bottom: 30px;
    }

    .product__menu {
        right: 484px;
    }

    .product__menu__inner {
        > ul {
            > li {
                > .product__subcategorys {
                    width: 437px;

                    a {
                        margin-bottom: 20px;

                        &:nth-child(3n+3) {
                            margin-right: auto;
                        }

                        &:nth-child(2n+2) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .verkooppunten__filter .btn {
        margin: 16px 5px 0 0;
        padding: 10px 16px;
        font-size: 15px;
    }

    .dealers__map .wpgmp_filter_wrappers {
        top: calc(100% - 106px);
        left: 2%;
    }

    .front__solutions__text .btn-hollow {
        max-width: 500px;
        text-align: left;
    }
}
