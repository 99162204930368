
.contact__block {
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;

    span {
        display: block;
        width: 100%;
    }

    a {
        color: $body-font-color;
    }
}

.contact__left {
    display: block;
    float: left;
    width: 155px;
    font-weight: 500;
}

.contact__right {
    display: block;
    float: left;
    width: calc(100% - 155px);
}

.contact__form {
    padding: 65px 55px;
    background: rgb(248, 249, 252);
    background: linear-gradient(0deg, rgba($secondarycolor, 1) 0%, rgba(248, 249, 252, 1) 100%);
    border-radius: 18px;
}
