.news__home {
    padding: 96px 0;

    @media screen and (max-width: 1024px) {
        padding: 48px 0;
    }
}

.news__home__overviewlink {
    margin-left: auto;
    margin-right: auto;
    text-decoration: underline;
    font-size: 16px;
    display: flex;
    justify-content: center;
}

.news__home__title {
    text-align: center;
    margin-bottom: 40px;

    h4 {
        font-size: 31px;
        line-height: 36px;
        font-weight: 500;
    }
}

.news__home__more {
    text-align: center;

    .btn-hollow {
        border: 2px solid $primarycolor;
        color: $primarycolor;

        &:hover {
            background-color: $primarycolor;
            color: #fff;
        }
    }
}
