@media screen and (max-width: 767px) {
    body {
        font-size: 14px;
    }

    h1 {
        margin-bottom: 20px;
        font-size: 24px;
    }

    .btn {
        padding: 10px 26px;
        font-size: 15px;
    }

    .content__bg {
        padding: 17px 22px;
    }

    .brochures__overview {
        padding: 20px 0;

        .btn-white {
            margin-bottom: 10px;
        }
    }

    .overview__padding {
        padding: 0 0 0 0;
    }

    .content__sub__single {
        padding: 0 0 40px 0;
    }

    .footer {
        padding: 60px 0 20px;
        font-size: 16px;
    }

    .footer__block {
        margin-bottom: 40px;
    }

    .footer__block__icon {
        margin-bottom: 15px;

        svg {
            width: 30px;
            height: 40px;
        }
    }

    .footer__block__title {
        margin-bottom: 15px;
    }

    .banner {
        margin-bottom: 30px;
    }

    .global__title {
        font-size: 18px;
    }

    .global__content {
        padding: 25px 30px;
    }

    .global__text {
        font-size: 14px;
    }

    .single__date {
        margin-top: -20px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 500;
    }

    .sidebar__container {
        padding: 25px 34px 32px 34px;
    }

    .sidebar__container__title {
        font-size: 21px;
    }

    .sidebar__container__items ul li a {
        font-size: 14px;
    }

    .row--gallery {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .brand {
        margin: 22px 0 0;
    }

    .brand img {
        width: 130px;
        height: 75px;
    }

    .menu__icon {
        height: 44px;
        padding: 27px 22px;

        &.open {
            span {
                &:nth-child(1) {
                    top: -6px;
                    left: 0;
                    width: 0%;
                }

                &:nth-child(2) {
                    top: -6px;
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    top: -6px;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .language__switcher {
        height: auto;
        margin: 0 10px 0 0;
        padding: 7px 8px 7px 12px;
        font-size: 16px;

        .language__switcher__dropdown {
            top: calc(100% + 5px);

            a {
                padding: 5px 10px;
                font-size: 16px;
            }
        }
    }

    .language__switcher__angle {
        svg {
            width: 17px;
            height: 17px;
        }
    }

    .menu__overlay__search {
        height: 44px;
        font-size: 18px;

        .search-submit {
            padding: 0 15px;
        }
    }

    .search__top {
        .search-form {
            .search-field {
                height: 41px;
            }
        }
    }

    .menu__word {
        font-size: 18px;
    }

    .menu__icon__bars {
        width: 16px;
    }

    .top__blue__layer .btn-hollow {
        padding: 10px 15px;
        font-size: 15px;
    }

    .intro__block__right {
        padding: 0 25px;
    }

    .intro__block__left {
        text-align: center;
    }

    .intro__block__bg {
        display: none;
    }

    .team__home__border {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .team__photo {
        top: 0;
        right: auto;
        left: auto;
        justify-content: center;
        width: 100%;
        border-radius: 0;

        &.team__photo--full {
            padding: 0;
        }
    }

    .team__contact__bg {
        display: block;
        padding: 44px 31px 39px 46px;
    }

    .team__photo__image::after {
        display: none;
    }

    .solution__list {
        margin-top: 50px;
        padding: 22px 10px 22px 10px;
    }

    .products__home__col {
        display: block;
        text-align: left;
    }

    .product__home__link {
        line-height: 30px;
    }

    .product__home__link:last-child {
        display: block;
    }

    .products__home {
        margin-top: 0;
        padding: 28px 0;
    }

    .projects__home__left {
        margin-top: 30px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .projects__home__right__inner {
        padding: 0;
    }

    .contact__form {
        padding: 30px 30px;
    }

    .gform_wrapper {
        .gform_title {
            font-size: 21px;
        }

        .gfield {
            width: 100%;
            margin-top: 13px;
        }

        .gform_fields {
            margin-top: 10px;
        }

        .gfield_label {
            font-size: 14px;
        }
    }

    .projecten__block {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .overview__padding--projecten {
        margin-bottom: 10px;
    }

    .projecten__detail__subtitle {
        margin-top: -25px;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .news__home__slider .global__col:last-child {
        display: block;
    }

    .small__contact-box {
        display: none;
    }

    .breadcrumbs__block {
        margin-bottom: 15px;
    }

    .overview__branches {
        margin-top: 10px;
    }

    .overview__solutions {
        margin-top: 10px;
    }

    .intro__block--branches {
        padding-bottom: 35px;

        .intro__block__left {
            text-align: left;

            img {
                margin-bottom: 10px;
            }
        }

        .intro__block__right {
            padding-left: 20px;
        }
    }

    .single__sidebar__title {
        margin-top: 25px;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .projects__relation__title {
        h3 {
            font-size: 19px;
        }
    }

    .projects__relation {
        padding: 40px 0 20px 0;

        .relation__arrow__right {
            right: 35px;
            width: 14px;
            height: 32px;
            background-size: 14px 32px;
        }

        .relation__arrow__left {
            left: 35px;
            width: 14px;
            height: 32px;
            background-size: 14px 32px;
        }
    }

    .products__relation__title {
        margin: 40px 0 20px 0;

        h2 {
            font-size: 19px;
        }

        h3 {
            font-size: 19px;
        }
    }

    .products__relation {
        padding-bottom: 40px;
    }

    .branches__cta__layer {
        padding: 50px 0;
    }

    .branches__cta__layer__content {
        h3 {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 1.3;
        }

        .btn {
            margin-top: 15px;
        }
    }

    .content__sub__single__nomargin {
        padding: 0 0 0 0;
    }

    .menu__overlay__title a {
        font-size: 20px;
    }

    .products__relation__blocks {
        .row {
            .global__col {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .solution__list__col {
        flex-direction: column;
        text-align: left;
    }

    .solution__link {
        align-self: auto;
        margin: 3px 0;
        font-size: 17px;
    }

    .productgroup__team__contact__layer {
        padding: 40px 0;

        .team__photo__image {
            margin-right: 40px;
        }

        .team__photo__image img {
            width: 170px;
            border-radius: 10px;
        }

        .team__home .team__photo__image img {
            width: 170px;
        }

        .team__photo {
            width: 100%;
            padding: 0;

            .team__photo__image {
                margin-right: 0;

                img {
                    width: 100%;
                }
            }
        }

        .team__question {
            padding: 25px 30px;
            text-align: center;
            border-radius: 10px;
            background-color: #d9e4e8;

            h4 {
                margin-bottom: 10px;
                line-height: 1.3;
            }
        }
    }

    .productgroup__team__title {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1.2;
    }

    #products__relation__id {
        padding-bottom: 40px;
    }

    .breadcrumbs__list {
        font-size: 13px;
    }

    .product__specifications__layer {
        margin-top: 40px;
        padding: 40px 0 40px 0;
    }

    .product__specifications__left {
        margin-bottom: 10px;
    }

    .product__specifications__title {
        font-size: 20px;
    }

    .product__team__voordelen__left {
        .btn-primary-team {
            min-width: 240px;
        }
    }

    .download__btn {
        height: 41px;
        padding-right: 60px;

        svg {
            top: 10px;
        }
    }

    .acf-map {
        height: 290px;
    }

    .live-search-container {
        margin-top: 20px;
    }

    .header__inner {
        height: 90px;
    }

    .header__inner__nooverlay {
        .product__menu {
            display: none;
        }
    }

    .header--fixed {
        position: absolute;
    }

    .product__menu {
        position: absolute;
        top: 120px;
        right: auto;
        left: 0;
        display: flex;
        margin-top: 0;
        transition: none;
    }

    .menu__open .product__menu {
        position: absolute;
        top: 120px;
        right: auto;
        left: 0;
        display: flex;
        margin-top: 0;
        transition: none;
    }

    .menu__open .right__menu__btns {
        margin: 0 0 0 auto;
    }

    .nav-primary__inner {
        margin-top: 110px;
    }

    .product__menu:hover .product__menu__inner ul {
        border-radius: 0 10px 10px 10px;
    }

    .product__menu__inner {
        font-size: 14px;

        > ul {
            right: auto;
            left: -1px;
            overflow-x: hidden;
            width: 280px;
            padding: 10px 0 10px 0;

            &:hover {
                > li {
                    > .productcategory__item {
                        border-right: 0;
                        background: transparent;
                    }
                }
            }

            > li {
                position: relative;
                padding: 0 20px;

                > .productcategory__item {
                    padding: 12px 25px 12px 35px;
                    font-size: 13px;

                    .productcategory__item__icon {
                        margin-top: -11px !important;

                        svg {
                            width: 22px !important;
                            height: 22px !important;
                        }
                    }
                }

                > .product__subcategorys {
                    z-index: 20;
                    left: 280px;
                    width: 280px;
                    transition: left 0s;

                    a {
                        padding: 13px 15px;
                        font-size: 13px;
                    }

                    .product__subcategorys__inner {
                        padding: 28px 20px 10px 20px;
                        pointer-events: all;

                        .back__button {
                            display: block;
                        }
                    }
                }

                &.product__subcategorys--active {
                    position: initial;

                    .product__subcategorys {
                        left: 0;
                        transition: left 0.3s;
                        display: flex;
                        pointer-events: none;

                        a,
                        .back__button {
                            pointer-events: all;
                        }
                    }

                    .productcategory__item__overlay {
                        display: none;
                    }
                }
            }
        }
    }

    .product__team__layer {
        padding: 40px 0 0 0;
    }

    .body--open {
        margin-top: 90px;
    }

    .solution__link:nth-of-type(3n) {
        display: inline-block;
    }

    .solution__list .btn-hollow-blue {
        display: inline-block;
        width: auto;
        max-width: 220px;
        margin: 10px 0 0 0;
    }

    .overview__filter__items .searchandfilter ul li label {
        margin-right: 10px;
        padding: 7px 13px;
        font-size: 12px;
    }

    .verkooppunten__map {
        margin-top: 40px;
    }

    .verkooppunten__map__inner {
        display: none;
    }

    .verkooppunten__filter__outer {
        padding-bottom: 100px;

        &.filter__open {
            z-index: 2;

            .verkooppunten__filter {
                position: absolute;
                z-index: 0;
                top: 28px;
                left: 0;
                display: block;
                width: 280px;
                padding-top: 21px;
                border: 1px solid #eee;
                background-color: #fff;
            }

            .verkooppunten__filter__title {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .verkooppunten__filter__title {
        position: relative;
        display: block;
        width: 280px;

        svg {
            position: absolute;
            top: calc(50% - 13px);
            right: 19px;
            width: 13px;
            height: 26px;
            transition: all 0.3s;
        }
    }

    .verkooppunten__filter {
        display: none;

        .btn {
            width: 100%;
            margin: 0;
            padding: 8px 20px;
            font-size: 15px;
            text-align: left;
            border: none;
            border-bottom: 1px solid #eee;
            border-radius: 0;
        }
    }

    .dealers__map {
        .wpgmp_filter_wrappers {
            top: -85px;
            left: 20px;
        }

        div {
            &.wpgmp_listing_header {
                div {
                    &.wpgmp_search_form {
                        &::before {
                            top: calc(50% - 9px);
                            right: 18px;
                            width: 17px;
                            height: 17px;
                            background-size: 17px;
                        }

                        input {
                            &.wpgmp_search_input {
                                width: 280px;
                                padding: 12px 60px 12px 30px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
