.breadcrumbs__block {
    margin-bottom: 30px;
}

.breadcrumbs__list {
    margin: 0;
    padding: 0;
    list-style: none;
    color: $primarycolor;
    font-size: 15px;

    li {
        align-items: center;
        display: flex;
        float: left;
        justify-content: center;

        svg {
            width: 8px;
            height: 20px;
            margin: 0 14px;
            color: $primarycolor;
        }
    }
}
