//.branches__home__offset {
//    overflow-x: hidden;
//    position: relative;
//    left: 10%;
//}

.branches__home {
    overflow-x: hidden;
    padding: 96px 0;
    background: #d9e6ec;

    @media screen and (max-width: 1024px) {
        padding: 48px 0;
    }
}

.branches__home__slider .slick-list {
    overflow: visible;
    padding: 0 !important;
    margin: 0 -15px;
}

.branches__home__offset.branches__home__offset--trigger {
    left: 0;
}

.branches__home__offset,
.branches__home__offset.branches__home__offset--trigger {
    transition: left 1s ease-in-out;
}

.branches__home__navigation {
    display: flex;
    column-gap: 9px;

    .branches__home__navigation__prev,
    .branches__home__navigation__next {
        width: 48px;
        height: 48px;
        border-radius: 999px;
        border: none;
        background: white;
        color: $primarycolor;
        font-size: 20px;
        outline: none;
        transition: 0.25s ease-in-out all;

        &:hover {
            background: $primarycolor;
            color: white;
        }
    }
}

.branches__home__content {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 25px;
    }
}

.branches__home__title {
    h4 {
        margin-bottom: 4px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
    }

    h2 {
        font-size: 40px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
            margin-bottom: 25px;
        }
    }
}

.branches__home__overviewlink {
    margin-top: 29px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: underline;
    font-size: 16px;
    display: flex;
    justify-content: center;
}

.branche__home__block {
    position: relative;
    outline: none;
    padding: 0 15px;

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

.branche__home__block__image {
    display: inline-block;
    width: 100%;

    > a {
        position: relative;
        display: inline-block;
        opacity: 1;
        width: 100%;

        &::after {
            position: absolute;
            content: '';
            background: rgba(0, 0, 0, 0.3);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.52) 0, rgba(0, 0, 0, 0) 100%);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            transition: all 0.3s;
            border-radius: 10px;
        }

        &:hover {
            &::after {
                background-color: transparent;
            }
        }
    }

    img {
        width: 100%;
        display: inline-block;
        border-radius: 10px;
    }
}

.branche__home__block__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    z-index: 1;
    padding: 0 35px 47px 56px;
    margin-bottom: 0;

    @media screen and (max-width: 1536px) {
        padding: 0 30px 29px 28px;
    }

    @media screen and (max-width: 1199px) {
        font-size: 26px;
        line-height: 25px;
    }

    @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 24px;
    }
}

.overview__branches {
    margin-top: 50px;
}

.projects__relation {
    padding: 80px 0;
    margin: 0;
    background-color: $fourthcolor;
    text-align: center;
}

.projects__relation__title {
    margin-bottom: 25px;

    b {
        display: block;
    }

    h3 {
        font-size: 25px;
    }
}

.intro__block--branches {
    padding-top: 0;
    padding-bottom: 80px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: flex-start;
        margin-bottom: 4px;

        svg {
            width: 20px;
            min-width: 20px;
            height: 24px;
            margin-top: 1px;
            color: $primarycolor;
            margin-right: 17px;
        }

        span {

        }
    }

    .intro__block__right {
        text-align: left;
        padding-top: 0;
    }

    p {
        font-size: 18px;
    }
}
