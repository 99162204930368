.col__project__home {
    text-align: center;

    &.full__columns {
        grid-row: span 2 / span 2;

        .projects__home__image {
            height: 100%;

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }
}

.projects__home__image {
    position: relative;
    display: block;
    border-radius: 10px;
    overflow: hidden;

    &::after {
        position: absolute;
        content: '';
        background-color: rgba(0, 0, 0, 0.3);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
    }

    img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 16/9;
    }
}

.projects__home {
    padding: 96px 0;
    border-bottom: solid 1px #d9e6ec;

    @media screen and (max-width: 1024px) {
        padding: 48px 0;
    }
}

.projects__home__left {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 22px;

    &:hover {
        .projects__home__image {
            &:not(:hover) {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.projects__home__right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects__home__right__inner {
    h4 {
        font-size: 40px;
        line-height: 1;
        display: block;
        font-weight: 600;
        margin-bottom: 25px;
    }

    p {
        display: block;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 28px;
    }
}

.projecten__block {
    position: relative;
    margin-top: 10px;
    margin-bottom: 40px;

    &:hover {
        .projecten__img__overlay {
            &::after {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.projects__relation__slider {

}

.projecten__img {
    position: relative;
    display: block;
}

.projecten__img__inner {
    position: relative;

    img {
        border-radius: 10px;
    }
}

.projecten__img__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 0 20px 30px 20px;
    line-height: 1.3;
    border-radius: 10px;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease;
    }
}

.projecten__block__title {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 17px;
    font-weight: 600;
}

.projecten__block__plaats {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 15px;
}

.overview__filter {
    display: flex;
    margin: 20px 0 40px 0;
}

.overview__filter__title {
    text-transform: uppercase;
    min-width: 155px;
    color: $primarycolor;
    font-size: 16px;
    margin-top: 10px;
}

.overview__filter__items {
    .searchandfilter {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                list-style: none;
                margin: 0;
                padding: 0;
                display: inline-block;

                &.sf-option-active {
                    label {
                        background-color: $primarycolor;
                        border-color: $primarycolor;
                        color: #fff;
                    }
                }

                input {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                label {
                    display: inline-block;
                    border: 2px solid #a1b7d0;
                    color: #5c738d;
                    font-size: 16px;
                    padding: 11px 25px;
                    margin-bottom: 10px;
                    margin-right: 15px;
                    background-color: transparent;
                    border-radius: 50px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        background-color: $primarycolor;
                        border-color: $primarycolor;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.projecten__detail__subtitle {
    color: $primarycolor;
    font-size: 26px;
    margin-top: -35px;
    margin-bottom: 25px;
    display: block;
    font-weight: 400;
}

.slick-slide {
    &:focus,
    a {
        outline: none;
    }
}

.galerij__groot {
    position: relative;
    display: block;
    margin-bottom: 12px;

    .slick-arrow {
        z-index: 2;
        height: 50px;
    }

    .slick-prev {
        left: 30px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 22px;
            height: 50px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJhbmdsZS1sZWZ0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtYW5nbGUtbGVmdCBmYS13LTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTkyIDUxMiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTQuMiAyNDcuNUwxNTEgOTkuNWM0LjctNC43IDEyLjMtNC43IDE3IDBsMTkuOCAxOS44YzQuNyA0LjcgNC43IDEyLjMgMCAxN0w2OS4zIDI1NmwxMTguNSAxMTkuN2M0LjcgNC43IDQuNyAxMi4zIDAgMTdMMTY4IDQxMi41Yy00LjcgNC43LTEyLjMgNC43LTE3IDBMNC4yIDI2NC41Yy00LjctNC43LTQuNy0xMi4zIDAtMTd6Ij48L3BhdGg+PC9zdmc+DQo=");
            background-size: 12px;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0.8;
            transition: all 0.3s;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }

    .slick-next {
        right: 30px;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 22px;
            height: 50px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJhbmdsZS1yaWdodCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFuZ2xlLXJpZ2h0IGZhLXctNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTg3LjggMjY0LjVMNDEgNDEyLjVjLTQuNyA0LjctMTIuMyA0LjctMTcgMEw0LjIgMzkyLjdjLTQuNy00LjctNC43LTEyLjMgMC0xN0wxMjIuNyAyNTYgNC4yIDEzNi4zYy00LjctNC43LTQuNy0xMi4zIDAtMTdMMjQgOTkuNWM0LjctNC43IDEyLjMtNC43IDE3IDBsMTQ2LjggMTQ4YzQuNyA0LjcgNC43IDEyLjMgMCAxN3oiPjwvcGF0aD48L3N2Zz4NCg==");
            background-size: 12px;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0.8;
            transition: all 0.3s;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }
}

.galerij__klein {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;

    .slick-slider {

    }

    .slick-slide {

    }

    .galerij__item {
        position: relative;
        cursor: pointer;

        img {
            border-radius: 5px;
        }

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 100%;
            content: '';
            border-radius: 5px;
            transition: all 0.3s;
        }

        &:hover {
            &::after {
                background-color: transparent;
            }
        }
    }
}

.galerij__item {
    display: block;
    margin: 0 6px;

    img {
        width: 100%;
        height: auto;
        cursor: pointer;
    }
}

.galerij__item__video__small {
    img {
        max-height: 77px;
    }

    svg {
        position: absolute;
        fill: #fff;
        left: 50%;
        top: 50%;
        margin-top: -20px;
        margin-left: -23px;
        width: 46px;
        height: 41px;
        z-index: 1;
    }
}

.galerij__item__video__big {
    img {
        max-height: 245px;
    }

    svg {
        position: absolute;
        fill: #fff;
        left: 50%;
        top: 50%;
        margin-top: -32px;
        margin-left: -36px;
        width: 72px;
        height: 64px;
        z-index: 1;
    }
}
