.footer {
    background-color: $secondarycolor;
    padding: 70px 0;
    font-size: 17px;
    line-height: 1.6;
    float: left;
    width: 100%;
}

.footer__block {
    text-align: center;
}

.footer__block__icon {
    display: block;
    margin-bottom: 30px;

    svg {
        fill: $primarycolor;
        height: 50px;
        width: 45px;
    }
}

.footer__block__title {
    display: block;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 500;
    color: $primarycolor;
}

.footer__contact__top {
    margin-bottom: 30px;

    span {
        display: block;
    }
}

.footer__contact__bottom {
    a {
        display: block;
        color: $body-font-color;
    }
}

.footer__social__bottom {
    display: block;
    margin-top: 30px;
}

.footer__social__link {
    margin-right: 7px;

    &:hover {
        svg {
            margin-top: -6px;
        }
    }

    &:last-child {
        margin-right: 0;
    }

    svg {
        fill: $primarycolor;
        transition: all 0.3s ease;
    }

    &.facebook {
        svg {
            height: 20px;
            width: 10px;
        }
    }

    &.youtube {
        svg {
            height: 19px;
            width: 23px;
        }
    }

    &.twitter {
        svg {
            height: 19px;
            width: 22px;
        }
    }

    &.linkedin {
        svg {
            height: 20px;
            width: 20px;
        }
    }

    &.instagram {
        svg {
            height: 21px;
            width: 21px;
        }
    }
}

.footer__newsletter {
    position: relative;
    display: block;
    margin-top: 30px;

    .btn-hollow {
        border: 2px solid $primarycolor;
        color: $primarycolor;

        &:hover {
            background-color: $primarycolor;
            color: #fff;
        }
    }
}

.fancybox-newsletter {
    .fancybox-content {
        max-width: 640px;
    }
}

.footer__menu {
    display: inline-block;
    padding: 0;
    margin: 0;
    text-align: left;

    li {
        display: block;

        a {
            position: relative;
            padding-left: 20px;
            color: $body-font-color;

            &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                background-image: url('../../images/angle-right-regular.svg');
                background-size: 11px 18px;
                width: 11px;
                height: 18px;
                transition: all 0.3s ease;
            }

            &:hover {
                &::before {
                    left: 3px;
                }
            }
        }
    }
}

.small__contact-box {
    position: fixed;
    left: 0;
    top: 50%;
    width: 40px;
    margin-top: -100px;
    text-align: center;
    z-index: 999;
    border-radius: 10px 0 0 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 1s;
}

.small__contact-box--visible {
    opacity: 1;
    visibility: visible;
}

.small__contact-icon {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    color: #3c3c3c;
    transition: ease 0.2s;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.23);
    background: $gradientcolor;

    &:nth-child(1) {
        border-radius: 0 10px 0 0;
    }

    &:nth-child(2) {
        border-radius: 0 0 10px 0;
    }
}

.small__contact-icon-text {
    position: absolute;
    left: 40px;
    top: 8px;
    height: 40px;
    width: auto;
    display: none;
    color: #fff !important;
    font-size: 16px;

    a {
        color: #fff !important;
    }
}

.phone-icon svg {
    position: absolute;
    left: 10px;
    top: 5px;
    margin-top: 8px;
    width: 15px;
    height: 15px;
    color: #fff;
    transform: rotate(-270deg);
}

.mail-icon svg {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
    color: #fff;
}
