@media screen and (max-width: 575px) {
    h1 {
        font-size: 20px;
        line-height: 26px;
    }

    h2 {
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 24px;
    }

    .oplossingen__h2 {
        font-size: 18px;
        line-height: 24px;
    }

    h3 {
        font-size: 16px;
        line-height: 24px;
    }

    h4 {
        font-size: 15px;
        line-height: 22px;
    }

    h5 {
        font-size: 15px;
        line-height: 22px;
    }

    h6 {
        font-size: 15px;
        line-height: 22px;
    }

    .intro__block h2 {
        font-size: 18px;
        line-height: 26px;
    }

    .news__home__title h4 {
        font-size: 20px;
        line-height: 26px;
    }

    .brand img {
    }

    .front__solutions__text {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .brochures__overview .btn-white {
        display: flex;
        height: auto;
        font-size: 13px;
        white-space: initial;
        text-overflow: initial;
    }

    .solution__item {
        padding-bottom: 10px;

        img {
            width: 100%;
            max-width: 100%;
            box-shadow: none;
        }
    }

    .front__solutions__text__inner h3 {
        font-size: 17px;
        line-height: 20px;
    }

    .front__solutions__text__inner p {
        margin-bottom: 14px;
        font-size: 15px;
        line-height: 20px;
    }

    .top__blue__layer .btn-hollow {
        font-size: 13px;
        text-align: center;
    }

    .front__solutions__text .slick-dots {
        bottom: -39px;
        left: 0;
        text-align: center;
    }

    .intro__block p {
        margin: 25px 0 4px;
        font-size: 15px;
        line-height: 22px;
    }

    .news__home__title {
        margin-bottom: 20px;
    }

    .team__phone {
        margin-right: 10px;
        font-size: 15px;
    }

    .team__email {
        font-size: 15px;
    }

    .team__phone svg {
        width: 18px;
        height: 18px;
        margin-right: 4px;
    }

    .team__email svg {
        width: 18px;
        height: 18px;
        margin-right: 4px;
    }

    .team__photo {
        display: block;
    }

    .team__photo__image {
        margin-right: 0;
    }

    .team__question {
        padding-bottom: 0;
    }

    .projects__home__right__inner h4 {
        font-size: 18px;
        line-height: 26px;
    }

    .product__home__link {
        display: inline-block;
        overflow: hidden;
        width: 48.5%;
        margin-right: 1%;
        line-height: 30px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:nth-child(even) {
            margin-right: 0;
        }
    }

    .product__home__link:last-child {
        display: inline-block;
    }

    .product__home__link svg {
        width: 10px;
        height: 14px;
        margin-right: 0;
    }

    .col__project__home {
        margin-bottom: 0;
    }

    .projects__home__right__inner p {
        font-size: 16px;
        line-height: 24px;
    }

    .branche__home__block__title {
        font-size: 16px;
    }

    .team__home {
        margin-bottom: 35px;
    }

    .btn,
    .comment-form input[type="submit"],
    .search-form .search-submit,
    .gform_footer [type=submit] {
        font-size: 14px;
    }

    .productgroup__team__contact__layer {
        .team__photo {
            background-color: transparent;
        }
    }

    .menu__word__container {
        display: none;
    }

    .menu__icon {
        min-width: auto;
        padding: 11px 15px;
    }

    .menu__icon__bars {
        width: 16px;
    }

    .header .nav a {
        overflow: visible;
        max-width: 100%;
        white-space: normal;
        text-overflow: inherit;
    }

    .product__team__link {
        padding-right: 0;
    }
}
