.overview__solutions {
    margin-top: 50px;
}

.solution__list {
    margin-top: 80px;
    padding: 25px 0;
    background-color: #ecf1f3;
    border-radius: 10px;
}

.solution__list__col {
    display: flex;
    text-align: center;
}

.solution__link {
    font-size: 20px;
    font-weight: 500;
    flex-grow: 1;
    color: $primarycolor;
    align-self: center;

    svg {
        width: 13px;
        height: 17px;
        color: $primarycolor;
        margin-right: 13px;
        transition: all 0.3s ease;
    }

    &:hover {
        text-decoration: none;

        svg {
            margin-right: 9px;
            margin-left: 4px;
        }
    }
}
