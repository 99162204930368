/** Search form */
.search-form {
    @extend .form-inline;
}

.search-form label {
    @extend .form-group;

    font-weight: normal;
}

.search-form .search-field {
    @extend .form-control;
}

.search-form .search-submit {
    @extend .btn;
    @extend .btn-secondary;
}

.gform_footer {
    [type=submit] {
        @extend .btn;
        @extend .btn-primary;
    }
}

.gform_wrapper {
    .gform_title {
        font-size: 25px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .gform_fields {
        display: inline-block;
        padding: 0;
        margin-top: 10px;
        margin-left: -20px;
        margin-right: -20px;
    }

    .gfield {
        display: block;
        float: left;
        width: 50%;
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .gfield_label {
        font-size: 16px;
        font-weight: 500;
    }

    [type=text],
    [type=password],
    [type=date],
    [type=datetime],
    [type=datetime-local],
    [type=month],
    [type=week],
    [type=email],
    [type=number],
    [type=search],
    [type=tel],
    [type=time],
    [type=url],
    [type=color],
    textarea {
        background-color: #fff;
        border-radius: 5px;
        border: none;
        width: 100%;
        padding: 10px 15px;
    }

    select {
        background-color: #fff;
        border-radius: 5px;
        border: none;
        width: 100%;
        padding: 10px 39px 10px 15px;
        height: 48px;
        appearance: none;
        background-image: url('../../svg/angle-down-regular.svg');
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: right 15px center;
    }

    textarea {
        height: 160px;
        resize: none;
    }

    .more-productgroup,
    .full-width {
        width: 100%;
    }

    .no__styling {
        display: none;
    }
}

.col__form {
    .ginput_container input {
        background-color: #f2f2f2;
    }

    textarea {
        background-color: #f2f2f2;
    }

    .ginput_container_select {
        select {
            background-color: #f2f2f2;
            width: 100%;
        }
    }
}

.validation_error {
    display: block;
    margin-top: 20px;
    color: red;
}

.validation_message {
    color: red;
}

.fancybox-content {
    [type=text],
    [type=password],
    [type=date],
    [type=datetime],
    [type=datetime-local],
    [type=month],
    [type=week],
    [type=email],
    [type=number],
    [type=search],
    [type=tel],
    [type=time],
    [type=url],
    [type=color],
    textarea,
    select {
        background-color: #f6f6f6;
    }
}

.hidden__form {
    display: none;
}
