@media screen and (max-width: 370px) {
    .dealers__map .wpgmp_infobox .wpgmp_infowindow {
        width: 280px !important;
    }
}

@media screen and (max-width: 420px) {
    .galerij__item__video__big img {
        max-height: 178px;
    }

    .galerij__item__video__small img {
        max-height: 54px;
    }

    .single__sidebar--max {
        max-width: 280px;
    }
}
