.btn {
    padding: 13px 32px;
    border-radius: 50px;
    border: none;
    font-size: 18px;
    font-weight: 600;
}

.btn-small {
    font-size: 14px;
    padding: 9px 25px;
}

.btn-medium {
    font-size: 15px;
    padding: 11px 25px;
}

.solution__list .btn-hollow-blue {
    border: 2px solid $primarycolor;
    color: $primarycolor;
    margin-top: 0;
    margin-left: 20px;

    &:hover {
        color: #fff;
        background-color: $primarycolor;
    }
}

.btn-primary {
    background: $gradientcolor;

    &:hover,
    &:active,
    &:focus {
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    }
}

.btn-secondary {
    background-color: $secondarycolor;
    color: $primarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: darken($secondarycolor, 10);
        color: $primarycolor;
    }
}

.btn-hollow {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;

    &:hover {
        color: #fff;
    }
}

.front__solutions__text .btn-hollow {
    font-size: 14px;
}

.btn-lightblue {
    background-color: #e4ebee;
    color: $primarycolor;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        color: $primarycolor;
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    }
}

.btn-white {
    background-color: #fff;
    color: $primarycolor;

    &:hover,
    &:active,
    &:focus {
        color: $primarycolor;
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    }
}

.btn-down {
    svg {
        width: 22px;
        height: 22px;
    }
}
