// Search
.search__top {
    position: relative;

    .search-form {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 500px;

        label {
            flex: 1;
            margin-bottom: 0;
        }

        .search-submit {
            margin-left: -20px;
            margin-top: 0;

            &:focus {
                box-shadow: none !important;
                background-color: darken($secondarycolor, 10) !important;
                color: $primarycolor !important;
            }
        }

        .search-field {
            margin: 0;
            padding: 8px 20px 8px 20px;
            width: 100%;
            border: 1px solid #eee;
            border-right: 0;
            border-radius: 0;
            outline: none;
            resize: none;
            height: 51px;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.search__container {
    display: inline-block;
}

.search__word {
    font-size: 18px;
    color: $primarycolor;
    display: block;

    b {
        font-weight: 700;
    }
}

.search__word__amount {
    display: block;
    margin: 30px 0 30px;
}

.search__amount {
    display: block;
    font-size: 14px;
    color: #939393;
}

.search__result__detail {
    position: relative;
    display: block;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ececec;

    &:hover {
        .search__result__detail__title {
            text-decoration: underline;
        }
    }
}

.search__result__detail__title {
    color: $primarycolor;
    margin-bottom: 3px;
    display: block;
    font-size: 20px;
    font-weight: 600;
}

.search__result__detail__path {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    color: #007db5;

    svg {
        position: relative;
        top: -1px;
        height: 14px;
        width: 5px;
        margin: 0 10px;
        fill: $secondarycolor;
        opacity: 0.9;
    }
}

.search__result__detail__text {
    display: block;
    color: $body-font-color;
}

input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

@media screen and (max-width: 1275px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 380px) {

}
