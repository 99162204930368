@media screen and (max-width: 991px) {
    .overview__padding {
        padding: 0 0 50px 0;
    }

    .content__sub__single {
        padding: 0 0 50px 0;
    }

    .content__sub__single__nomargin {
        padding-bottom: 0;
    }

    .product__team__voordelen {
        margin-bottom: 35px;
    }

    .overview__filter__items .searchandfilter ul li label {
        font-size: 14px;
        padding: 11px 20px;
    }

    .front__solutions__text {
        margin: 50px 0 85px;
        width: 60%;
        max-width: 60%;
    }

    .front__solutions__text .slick-dots {
        bottom: -49px;
    }

    .intro__block h2 {
        font-size: 24px;
    }

    .intro__block p {
        font-size: 18px;
        line-height: 27px;
    }

    .intro__block__right p {
        font-size: 21px;
        line-height: 30px;
    }

    .intro__block__bg {
        right: -7vw;
        width: 47vw;
    }

    .front__solutions__canvas__slider {
        right: 0;
    }

    .front__solutions__text__inner h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .btn-primary-team {
        left: 50%;
    }

    .team__contact__bg {
        padding: 44px 31px 39px 46px;
        display: block;
    }

    .team__phone {
        margin-bottom: 20px;
        margin-right: 0;
        font-size: 18px;
    }

    .team__email {
        font-size: 18px;
    }

    .team__question h4 {
        font-size: 16px;
        line-height: 17px;
    }

    .team__home {
        background: none;
        margin-bottom: 55px;
    }

    .projects__home__left {
        margin-top: 30px;
    }

    .product__home__link:last-child {
        display: none;
    }

    .contact__form {
        margin-top: 40px;
    }

    .overview__filter {
        flex-direction: column;
        margin: 10px 0 25px 0;
    }

    .overview__filter__title {
        margin: 0 0 20px 0;
    }

    .news__home__slider .global__col:last-child {
        display: none;
    }

    .intro__block--branches {
        .intro__block__right {
            padding-left: 60px;
        }
    }

    .solution__link {
        font-size: 17px;
    }

    #products__relation__id {
        padding-bottom: 0;
    }

    .product__team__voordelen__left {
        display: flex;
        justify-content: center;

        .btn-primary-team {
            width: auto;
        }
    }

    .menu__overlay__column {
        margin-bottom: 30px;
    }

    .menu__overlay__search {
        .search-submit {
            padding: 13px 18px;

            svg {
                margin-right: 0;
            }
        }

        .search-field {
            display: none;
        }
    }

    .header .nav a {
        padding: 5px 0;
        font-size: 14px;
        line-height: 20px;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .nav-primary li a {
        padding-left: 15px !important;

        &::before {
            top: 9px;
            width: 5px;
            height: 12px;
        }
    }

    .menu__overlay__title a {
        font-size: 20px;
    }

    .nav-primary__overlay--active {
        overflow: scroll;
    }

    .product__menu {
        right: 309px;
        margin-left: 0;
    }

    .brand {
        img {
            width: 160px;
            height: 75px;
        }
    }

    .header__inner {
        height: 130px;
    }

    .product__menu__inner {
        > ul {
            > li {
                > .product__subcategorys {
                    width: 300px;

                    a {
                        display: block;
                        width: 100%;
                        margin: 0 0 10px 0;

                        img {
                            display: none;
                        }

                        span {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .body--open {
        margin-top: 138px;
    }

    .solution__link:nth-of-type(3n) { display: none; }
}
