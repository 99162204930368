.team__home {
    position: relative;
    padding: 0 0 96px;

    @media screen and (max-width: 1024px) {
        padding: 0 0 48px;
    }

    .team__photo__image {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.team__home__border {
    overflow: hidden;
    border-radius: 10px;
    border: solid 1px #d9e4e8;
    display: flex;
}

.team__contact {
    z-index: 1;
    flex: 0 0 60%;
}

.team__contact__bg {
    padding: 84px 71px 79px 86px;
}

.team__contact__bg__title {
    font-weight: 600;
    margin-bottom: 20px;

    @media screen and (min-width: 1199px) {
        font-size: 40px;
        line-height: 1;
    }

    @media screen and (max-width: 575px) {
        font-size: 24px;
        line-height: 1;
    }
}

.team__contact__bg__content {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 32px;
}

.team__photo {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0;
    height: auto;
    background-color: $thirdcolor;
    border-radius: 10px 0 0 10px;
    z-index: 1;
    flex: 0 0 40%;
}

.team__photo--full {
    position: relative;
    display: block;
    top: auto;
    right: auto;
    width: 100%;
    border-radius: 10px;
    background-color: transparent;

    .team__photo__image {
        margin-right: 0;
    }

    .team__photo__image img {
        border-radius: 10px;
        width: 100%;
    }

    .team__question {
        padding: 40px;
        text-align: center;
        background-color: #d9e4e8;
        border-radius: 10px;
    }
}

.team__photo__image img {
    aspect-ratio: 16/9;
}

.footer__contact-phone {
    color: #000;
}

.team__phone {
    font-size: 24px;
    line-height: 32px;
    color: $primarycolor;
    font-weight: 600;

    svg {
        color: $primarycolor;
        width: 18px;
        height: 18px;
        margin-right: 18px;
        transform: rotate(90deg);
    }

    &:hover {
        text-decoration: none;
    }
}

.team__email {
    font-size: 24px;
    line-height: 32px;
    color: $primarycolor;
    font-weight: 600;

    svg {
        color: $primarycolor;
        width: 18px;
        height: 18px;
        margin-right: 18px;
    }

    &:hover {
        text-decoration: none;
    }
}

.team__photo__image {
    position: relative;
}

.team__question {
    align-self: center;
    margin-top: 34px;

    h4 {
        display: block;
        color: $primarycolor;
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 20px;
    }
}

.productgroup__team__contact__layer {
    .team__photo--full {
        .team__photo__image {
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 200px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $thirdcolor 100%);
                right: 0;
                bottom: 0;
                top: auto;
                z-index: 1;
                display: none;
            }
        }
    }

    .team__photo__image img {
        border-radius: 10px 0 0 10px;
        width: 370px;
        height: 100%;
        object-fit: cover;
        aspect-ratio: auto;
    }

    .team__phone {
        margin-right: 40px;

        &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $thirdcolor 100%);
            right: 0;
            top: 0;
        }
    }

    .team__email {
        font-size: 21px;
        line-height: 21px;
        font-weight: 500;

        svg {
            color: $primarycolor;
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }

    .team__photo__image {
        margin-right: 40px;

        &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $thirdcolor 100%);
            right: 0;
            top: 0;
        }
    }

    .team__question {
        margin-top: 0;
        padding-right: 40px;
    }
}
