.popup {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.4);
}

.popup__inner {
    position: relative;
    width: 400px;
    max-width: 90%;
}

.popup__inner__content {
    overflow: auto;
    max-height: 88vh;
    padding: 40px 40px 20px;
    border-radius: 5px;
    background-image: url('../../images/waterdruppel.png');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

.popup__close {
    position: absolute;
    top: -17px;
    right: -17px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    cursor: pointer;
    color: #fff;
    border-radius: 100%;
    background-color: $primarycolor;

    svg {
        width: 20px;
        height: 20px;
    }

    path {
        color: #fff;
        fill: #fff;
    }
}
